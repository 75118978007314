.collab-container {
    min-height: 80vh;
    width: 80%;
    padding: 5%;
    background-color: var(--reg-bg-transparent-dark);
    border-radius: var(--border-radius);
    box-shadow: 0 0 10px #333;
    text-align: center;
}

.collab-header {
    font-size: 2em;
}

.filter-row {
    width: 100%;
    top: var(--header-height);
    padding: 30px;
    text-align: left;
}

.initial-questions {
    display: grid;
    grid-template-columns: 4fr 2fr;
    grid-template-rows: auto;
    max-width: 300px;
}

.self-input {
    grid-column-start: 1;
    grid-column-end: 2;
}

.self-input input[type="text"] {
    display: block;
    width: 75%;
    padding: 2px;
}

.enter-button {
    padding-top: 20px;
}

.friends-info {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    grid-template-rows: auto 1fr;
    padding-top: 20px;
    max-width: 400px;
    column-gap: 30px;
    text-align: center;
}

.display-friends {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    display: flex;
}

.friends-names {
    flex: 1;
    margin-right: 10px;
}

.friends-buildings {
    flex: 1;
}


.another-button {
    padding-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 5fr;
}

.another-btn {
    background-color: var(--inverted-bg-solid);
    color: var(--inverted-text-color);
    border-color: var(--inverted-bg-solid);
    font-size: 1em;
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 2;
}

.delete-btn {
    background-color: var(--inverted-bg-solid);
    color: var(--inverted-text-color);
    border-color: var(--inverted-bg-solid);
    font-size: 1em;
    margin-left: 20px;
    width: 100%;
    flex: 1;
    grid-column-start: 2;
    grid-column-end: 3;
}

.friend-user-input {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
}

.friend-user-input input[type="text"]{
    display: block;
    width: 100%;
    padding: 2px;
    margin-bottom: 3px;
}

.friend-building-input {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
}

.friend-building-input input[type="text"]{
    display: block;
    width: 100%;
    padding: 2px;
    margin-bottom: 3px;
}

.note {
    font-size: 0.8em;
    grid-column-start: 1;
    grid-column-end: 2;
    display: block;
    width: 100%;
}

.submit-button {
    padding-top: 20px;
}

.time-day {
    padding-top: 20px;
}

.now-time {
    width: 5%;
    place-items: center;
    margin-left: 10px;
}

.time-day-inputs {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 20px;
    row-gap: 10px;
    justify-content: flex-start;
}

.day-input {
    display: flex;
    place-items: center;
}

.day-input input[type="text"] {
    display: block;
    width: 90px;
    margin-left: 10px;
    margin-right: 10px;
}

.time-input {
    display: flex;
    place-items: center;
}

.time-input input[type="text"] {
    display: block;
    width: 70px;
    margin-left: 10px;
}

.small-button {
    font-family: Outfit, sans-serif;
    border-radius: var(--border-radius);
    border-color: var(--inverted-bg-solid);
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    padding: 2px;
    font-size: 0.7em;
}

.walking-distance {
    font-size: 1em;
    text-align: left;
}