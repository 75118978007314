:root {
    --sb-red: #98111e;
    --sb-black: #564e58;
    --sb-dark-blue: #0b2141;
    --sb-light-blue: #6494aa;
    --sb-blue: #4b7080;
    --sb-tan: #ffb07b;
    --sb-yellow: #c99601;
    --sb-orange: #e24e1b;
    --sb-white: #f2efe9;
    --border-color: #cbcbcb;
    --sb-warning-bg: #ffb9c0;
    --border-radius: 5px;
    --header-height: 50px;
    --loudness-width: 0;
    --comfort-width: 0;
    --natlight-width: 0;
    --outlet-width: 0;
    --result-margin-bottom: 20px;
}

body {
    margin: 0;
    font-family: "Outfit", "Yellowtail", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dark-mode {
    --inverted-bg-solid: var(--sb-white);
    --inverted-bg-transparent: rgba(242, 239, 233, .8);
    --inverted-text-color: var(--sb-dark-blue);
    --reg-text-color: var(--sb-white);
    --reg-bg-solid: var(--sb-dark-blue);
    --reg-bg-rec-trans: rgba(242, 239, 233, 0.7);
    --reg-bg-transparent: rgba(11, 33, 65, 0.4);
    --reg-bg-transparent-dark: rgba(11, 33, 65, .8);
    --icon-filter: invert(100%) sepia(2%) saturate(5756%) hue-rotate(298deg) brightness(103%) contrast(89%);
    --inverted-filter: invert(10%) sepia(34%) saturate(2988%) hue-rotate(194deg) brightness(88%) contrast(96%);
    background-color: var(--sb-dark-blue);
    color: var(--sb-white);
}

.light-mode {
    --inverted-bg-solid: var(--sb-light-blue);
    --inverted-bg-transparent: rgba(11, 33, 65, .75);
    --inverted-text-color: var(--sb-white);
    --reg-text-color: var(--sb-light-blue);
    --reg-bg-solid: var(--sb-white);
    --reg-bg-rec-trans: rgba(11, 33, 65, .8);
    --reg-bg-transparent: rgba(242, 239, 233, 0.6);
    --reg-bg-transparent-dark: rgba(242, 239, 233, .9);
    --icon-filter: invert(39%) sepia(27%) saturate(540%) hue-rotate(153deg) brightness(96%) contrast(86%);
    --inverted-filter: invert(100%) sepia(2%) saturate(5756%) hue-rotate(298deg) brightness(103%) contrast(89%);
    background-color: var(--sb-white);
    color: var(--sb-blue);
}

.red {color: #ff4444}
.green {color: #40f140}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    font-family: Outfit, sans-serif;
    font-weight: 300;
}

a {
    text-decoration: none;
    color: inherit;
}
a:active { text-decoration: none; }
a:visited {
    text-decoration: none;
    color: inherit;
}
a:link { text-decoration: none; }

/* Text Classes */
.fw-100 {font-weight: 100;}
.fw-300 {font-weight: 300;}
.fw-500 {font-weight: 500;}
.fw-700 {font-weight: 700;}
.fw-900 {font-weight: 900;}

.text-left {text-align: left;}
.text-right {text-align: right;}
.text-center {text-align: center;}

.underline {text-decoration: underline}
.italic {font-style: italic}
.clickable {cursor: pointer}

h1.title {
    font-size: 3em;
    font-weight: 300;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

 /* Flexbox Classes */
.d-none {display: none !important;}
.d-flex {display: flex; align-items: center;}
.f-wrap {flex-wrap: wrap;}
.f-col {flex-direction: column;}
.jc-sb {justify-content: space-between;}
.jc-sa {justify-content: space-around;}
.jc-fs {justify-content: flex-start;}
.jc-fe {justify-content: flex-end;}
.jc-c {justify-content: center;}
.as-c {align-self: center;}
.as-fs {align-self: flex-start;}
.as-fe {align-self: flex-end;}
.gap-10{gap: 10px; grid-gap: 10px;}
.gap-20{gap: 20px; grid-gap: 20px;}
.gap-40{gap: 40px; grid-gap: 40px;}

.mb-40 {margin-bottom: 40px;}
.ml-20 {margin-left: 20px;}

.d-flex-row-l {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.d-flex-row-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

.d-flex-col-l {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.d-flex-col-c {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.f-responsive-row {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}


.btn {
    min-width: 90px;
    height: 40px;
    font-family: Outfit, sans-serif;
    border-radius: var(--border-radius);
    border-color: var(--inverted-bg-solid);
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    padding: 0 20px;
    font-size: 1em;
}

/* Grid Classes */
.d-grid {display: grid;}
.grid-col-12 {grid-template-columns: repeat(12, 1fr);}
.grid-4 {grid-column: span 4;}
.grid-6 {grid-column: span 6;}
.grid-8 {grid-column: span 8;}
.grid-12 {grid-column: span 12;}

/* Line Classes */

.line {
    width: 50px;
    height: 2px;
    background-color: var(--inverted-bg-solid);
    margin: 6px 0;
}

.inverted {
    background-color: var(--reg-bg-solid);
}
.black {background-color: black !important;}
.yellow {background-color: var(--sb-yellow) !important;}
.light-blue {background-color: var(--sb-light-blue) !important;}
.thick {height: 5px !important;}
.thin {height: 1px !important;}
.full-length {width: 100% !important;}

.fs-buffer {
    height: 100vh;
    width: 80%;
}

/* Icon Classes */

.icon {
    filter: var(--icon-filter);
    margin-right: 5px;
}

.dark-blue-icon {filter: invert(10%) sepia(34%) saturate(2988%) hue-rotate(194deg) brightness(88%) contrast(96%)}
.light-blue-icon {filter: invert(39%) sepia(27%) saturate(540%) hue-rotate(153deg) brightness(96%) contrast(86%);}
.warning-icon {filter: invert(18%) sepia(28%) saturate(6256%) hue-rotate(336deg) brightness(87%) contrast(108%);}
.invert-icon {filter: var(--inverted-filter) !important;}
.white-icon {--icon-filter: invert(100%) sepia(2%) saturate(5756%) hue-rotate(298deg) brightness(103%) contrast(89%) !important;}
.xxs-icon {width: 18px;}
.xs-icon {width: 22px;}
.sm-icon {width: 26px;}
.md-icon {width: 32px;}
.lg-icon {width: 40px;}
.xl-icon {width: 48px;}

.fill {
    width: 100%;
    height: 100%;
}

.map-overlay {
    width: 100%;
    min-height: 100vh;
    padding: calc(var(--header-height) * 1.5) 0;
    background-color: var(--reg-bg-transparent);
}

#map-bg {
    background-image: url("/src/media/ND_map_bw.png");
}

@media (max-width: 768px) {
    #header-nav {
        display: none !important;
    }

    #home-menu {
        text-align: center;
    }

    .home-intro {
        --home-typewriter-width: 290px !important;
        --carat-width: 5px !important;
        --carat-padding: 10px !important;
    }

    .home-intro h1 {font-size: 3em !important;}
    .home-intro p {font-size: 1.5em !important;}
    .typewriter {height: 40px !important;}

    #location-header {height: calc(100vw) !important;}
    #location-aside {display: none !important;}

    .form-container h2 {font-size: 1.5em !important;}

    .warning {font-size: .85em !important;}

    div.explore-building {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    div.explore-building-header {
        width: 100%;
        padding: 0;
    }

    div.explore-building-name {
        position: relative;
        margin: 10% 6% 0 10%;
        padding: 8%;
    }

    div.explore-see-all {
        position: static;
        font-size: .8em;
        margin: 0 6% 6% 6%;
    }

    div.explore-spots {
        grid-template-rows: repeat(3, calc(100vh / 7));
        padding: 6%
    }

    div.explore-spot:before {
        font-size: .8em;
    }

    aside.side-nav {display: none;}

    .similar-locations .explore-link {max-width: 100% !important;}
    .see-all-display {grid-template-columns: 1fr 1fr !important;}
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .home-intro {
        --home-typewriter-width: 560px !important;
        --carat-width: 10px !important;
        --carat-padding: 15px !important;
    }

    .home-intro h1 {font-size: 6em !important;}
    .home-intro p {font-size: 3em !important;}
    .typewriter {height: 90px !important;}
    aside.side-nav {display: none;}
}


@font-face {
    font-family: Outfit;
    font-weight: 100 900;
    src: url("../../media/fonts/Outfit/Outfit-VariableFont_wght.ttf") format("truetype");
}

@font-face {
    font-family: Yellowtail;
    src: url("../../media/fonts/Yellowtail/Yellowtail-Regular.ttf") format("truetype");
}

@font-face {
    font-family: College;
    src: url("../../media/fonts/College/college.ttf") format("truetype");
}
