#home-container {
    width: 100%;
    min-height: 100vh;
    margin-top: calc(var(--header-height) * -1.5);
    margin-inline: auto;
    padding-bottom: 50px;
}

#home-menu {
    background-color: var(--reg-bg-transparent-dark);
    padding: 50px 5%;
    width: 80%;
    min-height: 100vh;
    z-index: 10;
    border-radius: var(--border-radius);
    box-shadow: 0 0 20px var(--reg-bg-solid);
}

#home-menu > h1 {
    font-size: 3.5em;
    font-weight: 400;
}

#home-op-display {
    display: flex;
    justify-content: center;
    flex-flow: wrap;
    gap: 30px;
    max-width: 800px;
    margin: 50px;
}

.home-option {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    width: 250px;
    height: 150px;
    background-color: var(--inverted-bg-solid);
    color: var(--inverted-text-color);
    padding: 20px;
    border-radius: var(--border-radius);
    box-shadow: 0 0 5px var(--inverted-bg-solid);
    transition: all .25s;
}

.home-option:hover {
    box-shadow: 0 0 20px var(--inverted-bg-solid);
}

.home-option h1 {
    font-weight: 500;
}

.collaborate-option {
    font-size: 1.8em;
}

.home-option p {font-size: 1.1em;}

.home-intro {
    --home-typewriter-width: 820px;
    --carat-width: 15px;
    --carat-padding: 25px;
}

.home-intro h1 {
    font-family: College, sans-serif;
    font-size: 9em;
    cursor: default;
    font-weight: bold;
}

.home-intro p {
    font-family: Outfit, sans-serif;
    font-size: 4em;
    font-weight: 900;
    font-style: italic;
}

.welcome-text {
    width: var(--home-typewriter-width);
    padding: 10px calc(var(--carat-padding) + var(--carat-width)) 5px 15px;
    /*backdrop-filter: blur(5px);*/
    /*box-shadow: 0 0 10px var(--reg-bg-color);*/
    /*background-color: var(--reg-bg-rgba);*/
}

.typewriter {
    height: 140px;
    background-color: var(--sb-dark-blue);
    padding: 15px var(--carat-padding) 15px 15px;
    box-shadow: 0 0 10px var(--reg-bg-solid);
    display: flex;
    align-items: center;
}

.typewriter h1 {
    color: var(--sb-yellow);
    width: var(--home-typewriter-width);
    overflow: hidden;
    border-right: var(--carat-width) solid var(--sb-white);
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: 3px;
    animation:
            typing 1.5s steps(20, end),
            blink-caret .75s step-end infinite;
}

.down-arrow {
    width: 15vmax;
    animation: bouncing 1.5s ease-in-out infinite;
    position: absolute;
    bottom: 10%;
    cursor: pointer;
}

@keyframes typing {
    from { width: 0; }
    to { width: var(--home-typewriter-width); }
}

@keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: var(--sb-white); }
}

@keyframes bouncing {
    from, to {transform: translateY(40px)}
    50% {transform: translateY(80px)}
}