@keyframes modalOpacity {
    0% {background-color: rgba(0,0,0,0)}
    100% {background-color: rgba(0,0,0,0.7)}
}

@keyframes bumpOut {
    from {transform: translateX(100%)}
    80% {transform: translateX(-10%)}
    to {transform: translateX(0%)}
}

@keyframes slideOut {
    from {transform: translateX(100%)}
    to {transform: translateX(0%)}
}

@keyframes grow {
    from {width: 10px; height: 10px; padding: 0;}
    50% {width: min(400px,68%); height: 10px; padding: 0 40px;}
    to {width: min(400px, 68%); height: 60%; padding: 40px;}
}

@keyframes notify {
    from {top: -400px;}
    20% {top: 35px;}
    80% {top: 35px;}
    to {top: -400px;}
}

.modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
    z-index: 30;
    animation: modalOpacity .25s linear;
}

.modal-effect {
    background-color: var(--sb-yellow);
    position: absolute;
    right: 0;
    width: min(450px, 80%);
    height: 100vh;
    animation: bumpOut .25s ease-in-out;
}

.modal-main {
    background-color: var(--reg-bg-solid);
    display: flex;
    flex-flow: column;
    position: absolute;
    right: 0;
    width: min(400px, 68%);
    height: 100vh;
    margin-left: auto;
    padding: 40px 0 40px 40px;
    animation: slideOut .25s ease-in-out;
}

.modal-title {
    font-size: 3em;
    font-weight: 300;
    margin-bottom: 20px;
}

.options-display {
    display: flex;
    flex-flow: column;
    margin: 20px;
    font-size: 1.3em;
}

.options-display h2 {
    font-weight: 300;
}

.settings-button {
    width: 150px;
    font-family: Outfit, sans-serif;
    font-size: .8em;
    border: none;
    background-color: var(--inverted-bg-solid);
    color: var(--inverted-text-color);
    padding: 10px;
    margin: 10px 0;
    cursor: pointer;
    box-shadow: 0 0 0 var(--inverted-bg-solid);
    transition: all .2s;
}

.settings-button:hover{
    box-shadow: 0 0 5px var(--inverted-bg-solid);
}
.settings-button:active{
    transform: scale(110%);
}

.modal-nav-item {
    width: 100%;
    height: 50px;
    cursor: pointer;
    box-shadow: 0 0 0 var(--inverted-bg-solid);
    transition: background-color .3s, color .3s, box-shadow .2s;
}

.item-clicked {
    animation: modalOpacity 1s;
}

.notification {
    display: none;
    position: fixed;
    top: -400px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    background-color: var(--sb-yellow);
    color: var(--sb-white);
    width: 220px;
    height: 50px;
    border-radius: 25px;
    box-shadow: 0 0 10px var(--sb-yellow);
}

.notification-animation {
    display: flex !important;
    animation: notify 2.5s ease-in-out;
}

.sign-up {
    color: var(--sb-light-blue);
}

.sign-up a {
    text-decoration: underline;
}

.warning {
    background-color: var(--sb-warning-bg);
    box-shadow: 0 0 5px var(--sb-warning-bg);
    color: var(--sb-red);
    padding: 5px;
    border-radius: var(--border-radius);
}

.warning .warning {
    padding: 2px;
}
