.filter-title {
    margin-top: 20px;
    margin-bottom: 10px;
}

.search-container {
    width: 80%;
    padding: 5%;
    background-color: var(--reg-bg-transparent-dark);
    border-radius: var(--border-radius);
    box-shadow: 0 0 10px #333;
}

.search-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 20px;
    min-height: 100vh;
}

.filter-column {
    width: 25%;
    position: sticky;
    top: var(--header-height);
    padding: 30px;
    text-align: left;
}

.collaborate-container {
    width: 75%;
    background-color: var(--sb-white);
    color: var(--sb-yellow);
    padding: 20px;
    text-align: center;
    border-radius: var(--border-radius);
    box-shadow: 0 0 10px #333;
}

.results-container {
    width: 75%;
    background-color: var(--sb-white);
    color: var(--sb-yellow);
    padding: 20px;
    text-align: center;
    border-radius: var(--border-radius);
    box-shadow: 0 0 10px #333;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.result-item {
    --location-bg-img: linear-gradient(0deg, rgba(0,0,0),rgba(0,0,0,0));
    width: 100%;
    text-align: left;
    position: relative;
    display: flex;
    align-items: flex-end;
    color: var(--sb-white);
    border-radius: var(--border-radius);
    aspect-ratio: 4 / 3;
}
.result-item:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    border-radius: var(--border-radius);
    background-image: var(--location-bg-img);
    transition: all .3s;
}

.result-item:hover {
    --result-margin-bottom: 40px !important;
    cursor: pointer;
}

.result-header-info {
    z-index: 15;
    margin: 20px 20px var(--result-margin-bottom) 20px;
}
.result-header-info > h2 {font-size: 1.1em;}
.result-header-info > h3 {font-size: 1.7em;}
.result-header-info > p {
    font-size: 1.4em;
    padding: 0;
    color: var(--sb-yellow);
}

.result-item-header {
    transition: margin-bottom .2s;
}

.results-list {
    display: flex;
    flex-direction: row;
    width: 90%;
    padding: 5%;
    background-color: var(--inverted-bg-solid);
    color: var(--inverted-text-color);
    height: 200px;
}

.dropdown-container {
    color: black;
}

.spot-img {
    max-width: 25%;
    display: flex;
}

.spot-info {
    width: 75%;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
}

.rating input {
    position: absolute;
    left: -100vw;
}

.rating label {
    width: 48px;
    padding: 24px 0 0 0;
    color: var(--inverted-text-color);
    background: url('/src/media/icons/stars.svg') no-repeat top left;
    cursor: pointer;
}

.rating:not(:hover) input:indeterminate + label,
.rating:not(:hover) input:checked ~ input + label,
.rating input:hover ~ input + label {
    background-position : -48px 0;
}

.slider {
    -webkit-appearance: none;
    width: 70%;
    height: 5px;
    border-radius: var(--border-radius);
    background: var(--inverted-bg-solid);
    padding: 0;
    border-width: 0;
}

.slider:focus {
    padding: 0;
    border-width: 0;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    --slider-thumb-dim: 23px;
    appearance: none;
    width: var(--slider-thumb-dim);
    height: var(--slider-thumb-dim);
    border: 4px solid var(--sb-yellow);
    border-radius: var(--border-radius);
    background: var(--sb-white);
    cursor: pointer;
    transition: background-color .1s;
}

.slider::-webkit-slider-thumb:active {
    background-color: var(--sb-yellow);
}

.slider-num {
    margin-right: 10px;
    width: 12%;
    padding: 5px;
    text-align: center;
    font-size: 1em;
    font-weight: 800;
    border: 3px var(--sb-yellow) solid;
    border-radius: var(--border-radius);
    background-color: var(--sb-white);
    color: var(--sb-yellow);
    font-family: Outfit, sans-serif;
    transition: all .15s;
}

.slider-num:focus {
    padding: 5px;
    color: var(--sb-light-blue);
    border: 3px var(--sb-light-blue) solid;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

