#header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: var(--header-height);
    height: var(--header-height);
    width: 100%;
    position: fixed;
    z-index: 30;
    box-shadow: 0 0 10px black;
    backdrop-filter: blur(5px);
    background-color: var(--reg-bg-transparent-dark);
}

.header-buffer {
    height: var(--header-height);
    width: 100%;
}

#header-main {
    height: 100%;
    padding: 0 20px;
    cursor: pointer;
}

#header-main h1 {
    font-size: 1.9em;
    font-family: Yellowtail, sans-serif;
    font-weight: 100;
    color: var(--sb-yellow);
}

#header-logo {
    min-width: 40px;
    width: auto;
    height: calc(var(--header-height) - 10px);
    margin: 0 15px;
}

#search {
    --search-bg: var(--sb-white);
    background-color: var(--search-bg);
    border-radius: var(--border-radius);
    transition: all .15s;
}

#search:hover {
    --search-bg: var(--border-color);
}

.search-img {
    padding: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

#search input {
    border: none;
    padding: 5px 10px;
    background-color: var(--search-bg);
    color: var(--sb-dark-blue);
}

#search input:hover {
    border: none;
}

#header-nav {
    height: 100%;
    margin-right: 20px;
}

#header-nav a{
    height: 100%;
}

#settings-icon {
    --settings-width: 32px;
    margin-left: 20px;
    width: 40px;
    cursor: pointer;
    z-index: 50;
}

#settings-icon:hover {
    --settings-width: 38px;
    animation: rotate 2s linear infinite;
}

#settings-icon:active {
    --settings-width: 44px;
    animation: rotate 2s linear infinite;
}

.settings {
    width: var(--settings-width);
    margin: 0;
    transition: width .15s;
}

.nav-item {
    width: 150px;
    height: 100%;
    cursor: pointer;
    font-weight: 300;
    font-size: 1.4em;
    box-shadow: 0 0 0 var(--inverted-bg-solid);
    transition: background-color .3s, color .3s, box-shadow .2s, font .3s;
}

.nav-item:hover {
    color: var(--inverted-text-color);
    background-color: var(--inverted-bg-solid);
    box-shadow: 0 0 15px var(--sb-white);
}

.nav-item:active {
    background-color: rgba(255, 255, 255, 1);
}

.nav-item h2 {
    font: inherit;
}

#nav-menu {
    --nav-menu-bar-height: calc(var(--header-height) / 8);
    --nav-menu-height: calc(var(--nav-menu-bar-height) * 3.75);
    width: calc(var(--nav-menu-bar-height) * 5);
    height: var(--nav-menu-height);
    margin: var(--nav-menu-height) var(--nav-menu-height);
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    display: none;
    z-index: 50;
}

#nav-menu span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: var(--inverted-bg-solid);
    border-radius: 4px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

#nav-menu span:nth-child(1) {top: 0;}
#nav-menu span:nth-child(2),#nav-menu span:nth-child(3) {top: 12px;}
#nav-menu span:nth-child(4) {top: 24px;}
#nav-menu.open span:nth-child(1) {top: 18px;width: 0;left: 50%;}
#nav-menu.open span:nth-child(2) {transform: rotate(45deg);}
#nav-menu.open span:nth-child(3) {transform: rotate(-45deg);}
#nav-menu.open span:nth-child(4) {top: 18px;width: 0;left: 50%;}

@media only screen and (max-width: 769px) {
    #nav-menu {
        display: block;
    }
}

@keyframes rotate {
    from {rotate: 0deg;}
    to {rotate: 360deg;}
}
