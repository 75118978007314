.modal-form {
    background-color: var(--sb-white);
    display: flex;
    flex-flow: column;
    overflow: hidden;
    border-radius: var(--border-radius);
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: min(400px, 60%);
    height: 60%;
    padding: 40px;
}

.grow-animation {
    animation: grow .5s ease-in-out;
}

.form-container {
    gap: 20px;
    color: var(--sb-light-blue);
    width: 100%;
}

.form-container h2 {
    font-size: 2.1em;
    font-weight: 400;
}

.form-container h4 {
    font-weight: 400;
}

.form-container p {
    font-weight: 200;
}

#edit-container, #admin-container, #write-review {
    color: var(--sb-light-blue);
    align-items: center;
}

.form-buttons {
    gap: 10px;
    width: 100%;
}

.form-buttons .btn {
    border-color: var(--sb-light-blue);
    color: var(--sb-light-blue);
}

.like-button {
    cursor: pointer;
    margin-right: 20px;
}