#location-container {
    min-height: 100vh;
    width: 80%;
    padding: 5%;
    background-color: var(--reg-bg-transparent-dark);
    border-radius: var(--border-radius);
    box-shadow: 0 0 10px #333;
}

#location-header {
    --location-bg-img: linear-gradient(0deg, rgba(0,0,0),rgba(0,0,0,0));
    width: 100%;
    height: calc(100vw / 5 * 2);
    border-radius: var(--border-radius);
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: var(--sb-white);
}

#location-header:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    border-radius: var(--border-radius);
    background-image: var(--location-bg-img);
    transition: all .3s;
}

.location-header-info {
    z-index: 15;
    margin: 20px 20px var(--result-margin-bottom) 20px;
}
.location-header-info > h2 {font-size: 1.2em;}
.location-header-info > h3 {font-size: 1.8em;}
.location-header-info > p {
    font-size: 2em;
    padding: 0;
    color: var(--sb-yellow);
}

.see-all-btn {
    z-index: 15;
    background-color: transparent;
    color: var(--sb-white);
    margin: 20px 20px 20px -10px;
    transition: all .3s;
    border-color: var(--sb-white);
    height: auto;
    min-height: 40px;
    padding: 5px 15px;
}
.see-all-btn:hover {background-color: rgba(255, 255, 255, .3);}
.see-all-btn:active {background-color: rgba(255, 255, 255, .5);}

.see-all {
    color: var(--sb-light-blue);
    width: min(600px, 60%);
}

.see-all-title {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.see-all-photo {
    object-fit: cover;
}

.see-all-display {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.location-buttons {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
}

.location-buttons button, .location-info button {
    font-size: 1em;
    color: var(--reg-bg-solid);
    transition: background-color .3s;
    background-color: var(--inverted-bg-solid);
}

.location-buttons button:hover, .location-info button:hover {background-color: var(--inverted-bg-transparent);}

#write-review-btn, .submit-btn {
    background-color: var(--sb-yellow) !important;
    color: var(--sb-white) !important;
    border-color: var(--sb-yellow) !important;
    font-size: 1em !important;
}

#like-spot-btn, .submit-btn {
    background-color: var(--sb-yellow) !important;
    color: var(--sb-white) !important;
    border-color: var(--sb-yellow) !important;
    font-size: 1em !important;
}

.location-img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
    object-fit: cover;
}

#location-main {
    width: 100%;
}

.location-info {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
}

.location-info > h4 {margin-bottom: 20px;}

.stat-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 36px;
}

.stat-line > h3 {
    margin-right: 10px;
    font-weight: 300;
}

.stat-bar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--inverted-bg-solid);
    font-family: Outfit, sans-serif;
    font-weight: 700;
    font-size: 14px;
    width: 50%;
    height: 5px;
}

.stat-bar:before {
    background-color: var(--sb-yellow);
    height: 100%;
    content: "";
}

#loudness-bar:before {width: var(--loudness-width);}
#comfort-bar:before {width: var(--comfort-width);}
#natlight-bar:before {width: var(--natlight-width);}
#outlet-bar:before {width: var(--outlet-width);}

#location-aside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: flex-start;
    position: sticky;
    top: 10%;
    width: 30vw;
    height: 100%;
    margin: 20px 0 20px 20px;
}

.location-map {
    width: 30vmax;
    height: 20vmax;
    margin-bottom: 20px;
    border-radius: var(--border-radius);
}

.map-marker {
    background-color: var(--sb-yellow);
    height: 8px;
    width: 8px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
}

.map-marker:after {
    font-family: Outfit, sans-serif;
    font-size: .6em;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    content: attr(content);
    position: absolute;
    top: -50%;
    left: 50%;
    color: var(--sb-dark-blue);
    background-color: var(--sb-white);
    border: 1px solid var(--sb-dark-blue);
    border-radius: var(--border-radius);
    padding: 5px;
}

.review {
    color: var(--inverted-text-color);
    background-color: var(--inverted-bg-solid);
    margin-top: 20px;
    width: 90%;
    padding: 5%;
    border-radius: var(--border-radius);
    box-shadow: 0 0 10px #333;
}

.review-title {
    margin: 10px 0;
}

.small-text {
    margin-top: 10px;
    font-size: .8em;
}

.similar-locations {
    margin-top: 20px;
    font-size: .8em;
}

.similar-locations .explore-link {
    max-width: 250px;
}

.similar-locations .explore-spot:before {
    bottom: 3px;
}

.current {
    font-style: italic;
    font-weight: bold;
}