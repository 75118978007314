#upload-container {
    min-height: 100vh;
    width: 80%;
    padding: 5%;
    background-color: var(--reg-bg-transparent-dark);
    border-radius: var(--border-radius);
    box-shadow: 0 0 10px #333;
}

.upload-image {
    width: min(100%, 600px);
    aspect-ratio: 4 / 3;
    object-fit: cover;
}

.upload-input {
    --input-content: none;
    position: relative;
}

.upload-input:hover {--input-content: attr(content);}

.upload-input:before {
    content: var(--input-content);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    top: 0;
    right: 10%;
    max-width: 70%;
    height: auto;
    background-color: var(--sb-yellow);
    color: var(--sb-white);
    z-index: 20;
    padding: 8px;
    border-radius: var(--border-radius);
    transform: translateY(-110%);
    animation: fade-in .3s ease-in-out;
}

.upload-input input {width: min(100%, 300px)}

.upload-checkbox {margin-left: 30px;}

.upload-textarea {
    min-height: 150px;
    height: 150px;
    width: min(calc(100% - 2*min(5%, 20px)), 600px);
}

@keyframes fade-in {
    from {opacity: 0;}
    to {opacity: 1;}
}