.side-nav {
    color: var(--inverted-text-color);
    background-color: var(--inverted-bg-solid);
    border-radius: var(--border-radius);
    margin-left: 5vw;
    padding: 20px;
    position: sticky;
    position: -webkit-sticky;
    top: 75px;
    gap: 3px;
    align-self: flex-start;
}

.side-nav-link {
    padding: 5px;
    font-size: 1.4vh;
    cursor: pointer;
    border-radius: var(--border-radius);
    transition: all .2s;
}

.side-nav-link:hover {
    color: var(--reg-text-color);
    background-color: var(--reg-bg-solid);
}

.side-nav-link-scrolled {
    color: var(--reg-text-color);
    background-color: var(--reg-bg-solid);
}

.explore-page {
    width: 100%;
    margin-inline: 5vw;
}

.explore-building {
    width: 100%;
    box-shadow: 0 0 10px #333;
    background-color: var(--reg-bg-transparent-dark);
    position: relative;
    border-radius: var(--border-radius);

    display: grid;
    grid-template-areas: "header spots spots spots";
    grid-template-columns: repeat(4, 1fr);
}

.explore-building-header {
    position: relative;
    grid-area: header;
    padding: 36%;
}

.explore-building-name {
    min-width: auto;
    padding: 10%;
    position: absolute;
    top: 20%;
    z-index: 10;
    color: var(--inverted-text-color);
    background-color: var(--inverted-bg-solid);
    font-size: 1.6vmax;
    border: 7px solid var(--reg-bg-solid);
    border-radius: var(--border-radius);
    box-shadow: max(-50px, -3vw) max(-50px, -3vw) 0 var(--inverted-bg-transparent);
}

.explore-see-all {
    padding: 20px;
    position: absolute;
    bottom: 20%;
    left: 10%;
    font-size: 1.3vw;
    color: var(--inverted-text-color);
    background-color: var(--inverted-bg-solid);
    z-index: 10;
    border-radius: var(--border-radius);
    transition: box-shadow .2s;
}

.explore-see-all:hover {
    box-shadow: 0 0 10px var(--inverted-bg-solid);
}

.explore-spots {
    padding: 12%;
    grid-area: spots;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, calc(100vh / 4));
}

.explore-spot {
    position: relative;
    text-align: right;
    --explore-spot-height: 50px;
    min-height: 100px;
    border-radius: var(--border-radius);
    transition: transform .2s;
}

.explore-spot:hover {
    --explore-spot-height: 100px;
    transform: scale(1.02);
}

.explore-spot:before {
    content: attr(content);
    width: 90%;
    height: var(--explore-spot-height);
    padding-inline: 5%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    color: var(--sb-white);
    background-color: rgba(0, 0, 0, .7);
    transition: height .2s;
}

.explore-spot-img {
    object-fit: cover;
    box-shadow: 0 0 10px #222;
    border-radius: var(--border-radius);
}
