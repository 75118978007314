#sign-up-container {
    width: 100%;
    margin-top: calc(var(--header-height) * -1.5);
}

.wide-form {
    background-color: var(--sb-white);
    display: flex;
    flex-flow: column;
    border-radius: var(--border-radius);
    width: min(600px, 60%);
    padding: 40px;
    overflow: visible;
    box-shadow: 0 0 20px gray;
    transition: all .25s;
}